<template>
  <div>
    <div class="chat-header px-3 py-2 bg-white border-bottom">
      <img :src="user.avatar" :alt="user.name" style="height: 50px" />
      <div class="d-inline-block pl-3" style="vertical-align: bottom">
        <h6 class="mb-0" style="font-size: 18px">{{ user.name }}</h6>
        <p><small>Last seen: {{ user.last_seen }}</small></p>
      </div>
    </div>
    <div class="col-inside-lg decor-default">
      <div class="chat-body">
        <div class="chat-body-content">
          <div
            v-for="(message, i) in messages"
            :key="`message-${i}`"
            :class="`answer ${message.sent ? 'right' : 'left'}`"
          >
            <div class="text" v-if="message.type == 'text'" v-html="message.content"></div>
            <div class="text" v-if="message.type == 'image'">
              <img :src="message.content" :alt="message.content">
            </div>
            <div class="time" style="font-size: 12px;">{{ message.date }}</div>
          </div>
        </div>
        <div class="answer-add">
          <form @submit.prevent="sendMessage">
            <input
              placeholder="Write a message"
              v-model="new_message.content"
            />
            <a href="" @click.prevent="selectPhoto"
              ><span class="answer-btn answer-btn-1"></span
            ></a>
            <a href="" @click.prevent="sendMessage"
              ><span class="answer-btn answer-btn-2"></span
            ></a>
          </form>
          <input type="file" style="display: none" ref="selected_photo" id="select-photo" @change="sendPhoto">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      messages: [],
      user: {},
      new_message: {},
    };
  },

  methods: {
    selectPhoto() {
      document.getElementById('select-photo').click()
    },

    sendPhoto() {
      let data = new FormData
      data.append('content', this.$refs.selected_photo.files[0])
      data.append('type', 'photo')
      data.append('to', this.$route.params.id)

      this.$axios
        .post("/api/v1/moderator/messages", data, {
          headers: { "content-type": "multipart/form-data" },
        }).then(response => {
          this.messages.push(response.data.message);
        })
    },

    fetch() {
      this.$axios
        .get(`/api/v1/moderator/messages/${this.$route.params.id}`)
        .then((response) => {
          this.messages = response.data.messages;
          this.user = response.data.user;
        });
    },

    sendMessage() {
      let data = {
        to: this.$route.params.id,
        content: this.new_message.content,
        type: 'text'
      };

      this.$axios.post("/api/v1/moderator/messages", data).then((response) => {
        this.new_message = {};
        this.messages.push(response.data.message);
      });
    },
  },
};
</script>

<style scoped>
.row.row-broken {
  padding-bottom: 0;
}
.col-inside-lg {
  padding: 20px;
}
.search-users input {
  line-height: 1;
  height: auto;
}
.search-users input::placeholder {
  color: #404040;
}
.chat {
  height: auto;
}
.decor-default {
  background-color: #ffffff;
}
.chat-users h6 {
  font-size: 20px;
  margin: 0 0 20px;
}
.chat-users .user {
  position: relative;
  padding: 0 0 0 50px;
  display: block;
  cursor: pointer;
  margin: 0 0 20px;
}
.chat-users .user .avatar {
  top: 0;
  left: 0;
}
.chat .avatar {
  width: 40px;
  height: 40px;
  position: absolute;
}
.chat .avatar img {
  display: block;
  border-radius: 20px;
  height: 100%;
}
.chat .avatar .status.off {
  border: 1px solid #5a5a5a;
  background: #ffffff;
}
.chat .avatar .status.online {
  background: #4caf50;
}
.chat .avatar .status.busy {
  background: #ffc107;
}
.chat .avatar .status.offline {
  background: #ed4e6e;
}
.chat-users .user .status {
  bottom: 0;
  left: 28px;
}
.chat .avatar .status {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
}
.chat-users .user .name {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0;
}
.chat-users .user .name.text-bold,
.chat-users .user .mood.text-bold {
  font-weight: bold;
}
.chat-users .user .mood {
  font: 200 14px/20px "Raleway", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #404040;
}
/*****************CHAT BODY *******************/
.chat-body .chat-body-content {
  height: calc(100vh - 270px);
  overflow-y: auto;
}
.chat-body h6 {
  font-size: 20px;
  margin: 0 0 20px;
}
.chat-body .answer.left {
  padding: 0 0 0 10px;
  text-align: left;
  float: left;
}
.chat-body .answer {
  position: relative;
  max-width: 600px;
  overflow: hidden;
  clear: both;
}
.chat-body .answer.left .avatar {
  left: 0;
}
.chat-body .answer .avatar {
  bottom: 36px;
}
.chat .avatar {
  width: 40px;
  height: 40px;
  position: absolute;
}
.chat .avatar img {
  display: block;
  border-radius: 20px;
  height: 100%;
}
.chat-body .answer .name {
  font-size: 14px;
  line-height: 36px;
  padding-left: 0;
}
.chat-body .answer.left .avatar .status {
  right: 4px;
}
.chat-body .answer .avatar .status {
  bottom: 0;
}
.chat-body .answer.left .text {
  background: #ebebeb;
  color: #333333;
  border-radius: 8px 8px 8px 0;
}
.chat-body .answer .text {
  padding: 12px;
  font-size: 16px;
  line-height: 26px;
  position: relative;
}
.chat-body .answer.left .text:before {
  left: -30px;
  border-right-color: #ebebeb;
  border-right-width: 12px;
}
.chat-body .answer .text:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  border: 18px solid transparent;
  border-bottom-width: 0;
}
.chat-body .answer.left .time {
  padding-left: 12px;
  color: #333333;
}
.chat-body .answer .time {
  font-size: 16px;
  line-height: 36px;
  position: relative;
  padding-bottom: 1px;
}
/*RIGHT*/
.chat-body .answer.right {
  padding: 0 10px 0 0;
  text-align: right;
  float: right;
}

.chat-body .answer.right .avatar {
  right: 0;
}
.chat-body .answer.right .avatar .status {
  left: 4px;
}
.chat-body .answer.right .text {
  background: #7266ba;
  color: #ffffff;
  border-radius: 8px 8px 0 8px;
}
.chat-body .answer.right .text:before {
  right: -30px;
  border-left-color: #7266ba;
  border-left-width: 12px;
}
.chat-body .answer.right .time {
  padding-right: 12px;
  color: #333333;
}

/**************ADD FORM ***************/
.chat-body .answer-add {
  clear: both;
  position: relative;
  margin: 20px -20px -20px;
  padding: 20px;
  background: #46be8a;
}
.chat-body .answer-add input {
  border: none;
  background: none;
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  color: #ffffff;
}
.chat-body .answer-add input:focus {
  outline: none;
}
.chat-body .answer-add input::placeholder {
  color: #fff;
}
.chat input {
  -webkit-appearance: none;
  border-radius: 0;
}
.chat-body .answer-add .answer-btn-1 {
  background: url("/theme/gallery.svg") 50% 10% no-repeat;
  right: 70px;
}
.chat-body .answer-add .answer-btn {
  display: block;
  cursor: pointer;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
}
.chat-body .answer-add .answer-btn-2 {
  background: url("/theme/play.svg") 50% 50% no-repeat;
  right: 20px;
}

.chat input {
  -webkit-appearance: none;
  border-radius: 0;
}
</style>
